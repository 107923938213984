import { axiosBackEnd,setCookie } from "../utils/helper"
import { USERNAME, AVATAR, TOKEN } from "../utils/constant"
import { message } from "antd"

const option = {
  headers: {
    "Content-Type": "application/json",
  },
}

export function signIn(req, callback) {
  axiosBackEnd
    .post("/auth/signIn", req, option)
    .then(res => {
      if (res.data.success) {
        const { data } = res.data
        setCookie(TOKEN, data.token)
        setCookie(USERNAME, data.username)
        setCookie(AVATAR, data.avatar)
        callback()
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export function signUp(req, callback) {
  axiosBackEnd
    .post("/auth/register", req, option)
    .then(res => {
      const { data } = res
      if (data.success) {
        message.success("注册成功并直接登录！")
        callback()
      }
    })
    .catch(err => {
      message.error(err.response)
    })
}

export function sendEmail(req) {
  return axiosBackEnd.post(`/auth/email`, req, option)
}

export function sendResetEmail(req, callback) {
  axiosBackEnd
    .post(`/auth/resetEmail`, req, option)
    .then(res => {
      const { data } = res
      if (data.success) {
        message.success("邮件发送成功")
        callback()
      }
    })
    .catch(err => {
      message.error(err.response)
    })
}

export function resetPass(req, callback) {
  axiosBackEnd
    .put("/auth/resetPass", req, option)
    .then(res => {
      const { data } = res
      if (data.success) {
        message.success("修改密码成功")
        callback()
      }
    })
    .catch(err => {
      message.error(err.response)
    })
}
