import compress from "../icons/compress.svg"

export const USERNAME = "username"
export const AVATAR = "avatar"
export const USER_OUT_ID = "userOutId"
export const TOKEN = "token"
export const PREVIEW_TYPE = "previewType"

export const DEVELOPER_TAG = "开发者文档"
export const USER_TAG = "用户文档"
export const INTRO_TAG = "介绍文章"

export const USER_THEME_TYPE_SUBSCRIBE = 0
export const USER_THEME_TYPE_BUY = 1
export const USER_THEME_TYPE_DESIGN = 2

export const RANDOM_CODE_COUNTDOWN = 60
export const FIRST_PAGE = 1;
export const PLATFORM_RECORDS_PAGE_SIZE = 10;
export const INVITATIONS_PAGE_SIZE = 10;

export const ENTER_DELAY = 0.5;
export const LEAVE_DELAY = 0.0;

export const TOOLS = [
  {
    banner: compress,
    title: "图片压缩",
    desc: "在线压缩图片",
    link: "/tools/compress-picture",
  },
]

export const MEDIA_INTRO = [
  {
    name: "五分钟学算法 | 神器 MdNice ！",
    description: "评价：发现还是 mdnice 最适合喜欢使用 MarkDown 语法来记录笔记的公众号号主",
    link: "https://mp.weixin.qq.com/s/9MRV_vzxQtWZhwvRA8Vtow",
    avatar: "https://files.mdnice.com/pic/bd60e0d0-0052-462f-aecd-9ced352f3e29.png",
  },
  {
    name: "好玩的数学 | 如何在微信公众号中编辑出漂亮的数学公式?",
    description: "评价：Markdown Nice 现在可以很方便在微信公众号里展示数学公式了",
    link: "https://mp.weixin.qq.com/s/6v32da5WBTrdFMl7R7cEmA",
    avatar: "https://files.mdnice.com/pic/374582a2-4aa6-4c4b-82eb-b2933c3cfb12.jpeg",
  },
  {
    name: "GitHub大本营 | 一款可以吹爆的 GitHub开源 Markdown 编辑器",
    description: "评价：mdnice 对于在运营微信公众号号主在来说太好用了，墙裂推荐",
    link: "https://mp.weixin.qq.com/s/R6XPTUVBz4GhLZhOvzQYIg",
    avatar: "https://files.mdnice.com/pic/2b97f003-07f8-49d3-a4c1-e93b2f5a4df8.jpeg",
  },
  {
    name: "HelloGitHub | 和微信公众号编辑器战斗的日子",
    description: "评价：Markdown Nice 将用户从排版中释放出来，只关注内容本身",
    link: "https://mp.weixin.qq.com/s/3-A_iSZYD88Cy467qOnNOw",
    avatar: "https://files.mdnice.com/pic/5471cd4a-3e90-4804-bb01-3ec689dafaab.jpg",
  },
  {
    name: "Stata 连享会 | 公众号和知乎排版神器 - mdnice.com",
    description: "评价：mdnice 极大的减轻了微信公众号文章的排版和编辑工作",
    link: "https://zhuanlan.zhihu.com/p/104209040",
    avatar: "https://files.mdnice.com/pic/0b16c2ad-41e6-4237-b49e-416205e2e0ac.jpg",
  },  
]

export const PLATFORM_RECORD_STATUS = {
  RUNNING: 1,
  SUCCESS: 2,
  FAILURE: 3,
}

export const VIP_TYPE = {
  USER: 1,
  VIP: 2,
  SUPER_VIP: 3,
}

export const MEMBERSHIP_SIDEBAR = {
  VIP: {
    name: "会员权益",
    url: "/membership/vip"
  },
  INVITE: {
    name: "邀请送会员",
    url: "/membership/invite"
  },
  OPEN_VIP: {
    name: "会员开通",
    url: "/membership/open-vip"
  },
  SELL_COIN: {
    name: "墨点充值",
    url: "/membership/sell-coin"
  },
}

export const INVITATION_STATUS_LABEL = {
  1: "已注册",
  2: "已绑定公众号",
}