import React, { useState, useEffect, useRef, useImperativeHandle } from "react"
import {
  LockOutlined,
  UserOutlined,
  SendOutlined,
  SafetyOutlined,
} from "@ant-design/icons"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Input, Button, Row, Col } from "antd"

import { signUp, sendEmail, signIn } from "../api/account"
import { getQrcode, checkLogin } from "../api/wechat"
import { RANDOM_CODE_COUNTDOWN } from "../utils/constant"
import { queryParse, setCookie } from "../utils/helper"
import { USERNAME, AVATAR, TOKEN } from "../utils/constant"
import "./WechatSignIn.css"

function WechatSignIn(props) {
  const [isNext, setNext] = useState(false)
  const [qrCode, setQrCode] = useState("")
  const [isExpired, setExpired] = useState(false)
  const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false)
  const [codeBtnText, setCodeBtnText] = useState("获取验证码")

  // ref 对象可以确保在整个生命周期中值不变，且同步更新，是因为 ref 的返回值始终只有一个实例，所有读写都指向它自己
  // https://juejin.im/post/6844903974647103496
  const timer = useRef(0)
  const { form, successCallback } = props
  const { getFieldDecorator } = form

  useImperativeHandle(props.wechatSignInRef, () => ({
    clearTimer: () => {
      clearTimeout(timer.current);
    },
  }));

  let sceneStr = ""
  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const res = await getQrcode()
    const data = res.data.data
    if (data) {
      setExpired(false)
      setQrCode(data.qrCode)
      sceneStr = data.sceneStr
      startCheckLoginCountdown()
    }
  }

  // 二维码 RANDOM_CODE_COUNTDOWN 秒之后过期需要重新获取，避免无限轮询
  let checkLoginCountdown = RANDOM_CODE_COUNTDOWN
  const startCheckLoginCountdown = async () => {
    if (checkLoginCountdown === 0) {
      checkLoginCountdown = RANDOM_CODE_COUNTDOWN
      setExpired(true)
      clearTimeout(timer.current)
    } else {
      checkLoginCountdown--
      const res = await checkLogin(sceneStr)
      const data = res.data.data

      // 登录态
      if (res.data.code === 4) {
        setCookie(TOKEN, data.token)
        setCookie(USERNAME, data.username)
        setCookie(AVATAR, data.avatar)
        successCallback()
        return
      }

      // 注册态
      if (data) {
        // 进入下一阶段，停止轮询
        setNext(true)
        clearTimeout(timer.current)
        props.form.setFieldsValue({
          username: data.username,
          openId: data.openId,
          avatar: data.avatar,
        })
        return
      }
      timer.current = setTimeout(function () {
        startCheckLoginCountdown()
      }, 1000)
    }
  }

  let countdownNum = RANDOM_CODE_COUNTDOWN
  function setCountdown() {
    if (countdownNum === 0) {
      setCodeBtnDisabled(false)
      setCodeBtnText("获取验证码")
      countdownNum = RANDOM_CODE_COUNTDOWN
    } else {
      setCodeBtnDisabled(true)
      setCodeBtnText("重新发送(" + countdownNum + ")")
      countdownNum--
      setTimeout(function () {
        setCountdown()
      }, 1000)
    }
  }

  const sendCodeEmail = async () => {
    setCodeBtnDisabled(true)

    const email = form.getFieldValue("email")
    const res = await sendEmail({ email })
    if (res.data.success) {
      setCountdown()
      return;
    }
    setCodeBtnDisabled(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        const param = queryParse();
        let data = { ...values }
        if (param.code) {
          data.inviterUserOutId = param.code;
        }
        signUp(data, () => {
          form.resetFields()
          const userInfo = {
            email: data.email,
            password: data.password,
          };
          signIn(userInfo, () => {
            successCallback();
          });
        })
      }
    })
  }

  function validatePassword(rule, value, callback) {
    if (!value) {
      callback("请输入密码");
    } else if (value.length < 6 || value.length > 20) {
      callback("密码必须为6-20位的字符");
    } else {
      callback();
    }
  }

  async function validateAuthorName(rule, value) {
    if (!value) {
      return Promise.reject("请输入用户名")
    } else if (value.length > 20) {
      return Promise.reject("用户名不能超过20个字符")
    } else if (!/^[a-z0-9\u2E80-\u9FFFA-Z]{1,20}$/.test(value)) {
      return Promise.reject("用户名只能是数字、汉字和英文的组合")
    }
    return Promise.resolve()
  }

  async function validateRandomCode(rule, value) {
    if (!value) {
      return Promise.reject("请输入验证码")
    }
    return Promise.resolve()
  }

  async function validateEmail(rule, value) {
    return Promise.resolve()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <h2>
          {isNext ? (
            <>
              注册账号<p>老用户用于绑定微信和验证邮箱，原账号还在</p>
            </>
          ) : (
            "微信扫码登录/注册"
          )}
        </h2>
        {isNext ? (
          <>
            <Form.Item>
              {getFieldDecorator("openId")(<Input hidden />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("avatar")(<Input hidden />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    validator: validateAuthorName,
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined />}
                  placeholder="用户名，比如：小机智"
                  allowClear
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{ required: true, validator: validateEmail }],
              })(
                <Input
                  prefix={<SendOutlined />}
                  placeholder="邮箱地址，比如：example@exapmle.com"
                  allowClear
                />
              )}
            </Form.Item>
            <Form.Item>
              <Row gutter={16}>
                <Col span={15}>
                  <Form.Item style={{ marginBottom: "0px" }}>
                    {getFieldDecorator("randomCode", {
                      rules: [
                        { required: true, validator: validateRandomCode },
                      ],
                    })(
                      <Input
                        prefix={<SafetyOutlined />}
                        placeholder="6 位邮箱验证码"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Button disabled={isCodeBtnDisabled} onClick={sendCodeEmail}>
                    {codeBtnText}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ validator: validatePassword }],
              })(
                <Input.Password prefix={<LockOutlined />} placeholder="密码" />
              )}
            </Form.Item>
            <Button
              className="signin-sumbit-btn"
              type="primary"
              htmlType="submit"
            >
              我要注册啦！
            </Button>
          </>
        ) : (
          <div className="first">
            {isExpired ? (
              <a onClick={init}>
                <div className="expired">
                  <p>二维码已过期</p>
                  <p>点击重新获取</p>
                </div>
              </a>
            ) : (
              <img className="signin-qrcode" src={qrCode} />
            )}
            <p className="explain">关注"墨滴软件官方服务号"进行注册</p>
          </div>
        )}
      </div>
    </Form>
  )
}

export default Form.create()(WechatSignIn)
