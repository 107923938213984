import { Avatar } from "antd"
import React from "react"

export default ({ avatar, username, size }) => {
  return (
    <>
      {avatar && avatar.startsWith("http") ? (
        <Avatar size={size} src={avatar} />
      ) : (
        <Avatar size={size}>{username && username[0]}</Avatar>
      )}
    </>
  )
}
