import React, { useState, useEffect, useRef } from "react"

import NavItem from "../components/NavItem"
import logo from "../icons/logo.svg"
import menu from "../icons/menu.svg"

import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Modal, Avatar } from "antd"
import { navigate } from "gatsby"

import "./navbar.css"
import {
  USERNAME,
  AVATAR,
  USER_OUT_ID,
  TOKEN,
} from "../utils/constant"
import { COMMUNITY_URL } from "gatsby-env-variables"
import { getCookie, removeCookie } from "../utils/helper"
import SignIn from "../components/SignIn"
import WechatSignIn from "../components/WechatSignIn"
import Reset from "../components/Reset"
import { getUserSelf } from "../api/user"
import UserAvatar from "../components/UserAvatar"

function Navbar(props) {
  const navs = props.navs
  const [token, setToken] = useState("")
  const [username, setUsername] = useState("")
  const [userOutId, setUserOutId] = useState("")
  const [avatar, setAvatar] = useState("")
  const [coinAmount, setCoinAmount] = useState(0)
  const [activeTab, setActiveTab] = useState("wechatLogin")

  const wechatSignInRef = useRef(0)

  const successSignIn = () => {
    props.handleSignInLoginDisplay(false);
    setToken(getCookie(TOKEN))
    setUsername(getCookie(USERNAME))
    setAvatar(getCookie(AVATAR))
    setUserOutId(getCookie(USER_OUT_ID))

    // 先通过刷新的方式更新状态
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  useEffect(() => {
    setToken(getCookie(TOKEN))
    setUsername(getCookie(USERNAME))
    setAvatar(getCookie(AVATAR))
    setUserOutId(getCookie(USER_OUT_ID))
  }, [])

  const showModal = () => {
    props.handleSignInLoginDisplay(true);
  }

  const handleCancel = e => {
    props.handleSignInLoginDisplay(false);
    wechatSignInRef.current.clearTimer();
  }

  const handleExit = () => {
    removeCookie(USERNAME)
    removeCookie(AVATAR)
    removeCookie(USER_OUT_ID)
    removeCookie(TOKEN)
    setToken("")
    setUsername("")
    setAvatar("")
    setUserOutId("")
    // 先通过刷新的方式更新状态
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  const handleCoin = () => {
    navigate("/coins")
  }

  const handleManageTheme = () => {
    navigate("/themes/manage")
  }

  const handleViewOrder = () => {
    navigate("/orders")
  }

  const handleViewInvitation = () => {
    navigate("/invitations")
  }

  const handleViewActiveCode = () => {
    navigate("/membership/active-code")
  }

  const handlePlatformRecords = () => {
    navigate(`/platform-records`)
  }

  const handleUserHomepage = () => {
    window.open(`${COMMUNITY_URL}/user/${userOutId}`)
  }

  const handleSetting = () => {
    window.open(`${COMMUNITY_URL}/user/setting`)
  }

  const fetchUserInfo = async e => {
    e.preventDefault()
    const res = await getUserSelf()
    if (res && res.data.success) {
      const { coinAmount, userOutId } = res.data.data
      setCoinAmount(coinAmount)
      setUserOutId(userOutId)
    }
  }

  const userMenu = (
    <Menu>
      <Menu.Item>
        <a style={{ color: "rgb(48,132,214)" }} onClick={handleCoin}>
          墨点：{coinAmount}
        </a>
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item>
        <a onClick={handleUserHomepage}>我的主页</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handleSetting}>资料设置</a>
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item>
        <a onClick={handleViewOrder}>查看订单</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handleViewInvitation}>查看邀请记录</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handleViewActiveCode}>查看激活码</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handlePlatformRecords}>查看分发记录</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={handleExit}>退出登录</a>
      </Menu.Item>
    </Menu>
  )

  let headerStyle = "--none"
  if (props.isNavbarDisplay) {
    if (props.isFixedNavbar) {
      headerStyle = "mdnice-navbar-fixed"
    } else {
      headerStyle = "mdnice-navbar"
    }
  }

  let display
  if (activeTab === "login") {
    display = (
      <SignIn successCallback={successSignIn} setActiveTab={setActiveTab} />
    )
  } else if (activeTab === "wechatLogin") {
    display = <WechatSignIn successCallback={successSignIn} visible={props.isSignInDialogDisplay} wechatSignInRef={wechatSignInRef} />
  } else if (activeTab === "reset") {
    display = <Reset successCallback={() => setActiveTab("login")} />
  }
  return (
    <>
      <header className={headerStyle}>
        <div className="mdnice-navbar-container">
          <div className="mdnice-navbar-left">
            <a href="/">
              <img src={logo} className="mdnice-icon" alt="mdnice-icon" />
            </a>
          </div>
          <nav className="mdnice-navbar-right">
            {navs.map(item => (
              <NavItem
                key={item.text}
                path={item.path}
                text={item.text}
                activeClass="mdnice-navbar-btn --navbar-btn-active"
                defaultClass="mdnice-navbar-btn"
              />
            ))}

            {token ? (
              <>
                <UserAvatar avatar={avatar} username={username} size="small" />
                <Dropdown
                  trigger="click"
                  overlay={userMenu}
                  placement="bottomRight"
                >
                  <a
                    className="ant-dropdown-link"
                    style={{ paddingLeft: "10px" }}
                    onClick={fetchUserInfo}
                  >
                    {username} <DownOutlined />
                  </a>
                </Dropdown>
              </>
            ) : (
              <a className="mdnice-navbar-btn-login" onClick={showModal}>
                登录 / 注册
              </a>
            )}

            <div className="mdnice-menu">
              <img
                src={menu}
                className="mdnice-menu-icon"
                alt="menu-icon"
                onClick={props.menuClick}
              />
            </div>
          </nav>
        </div>
      </header>
      <div className={props.isSpacerDisplay ? "navbar-spacer" : "--none"} />
      <Modal
        visible={props.isSignInDialogDisplay}
        onCancel={handleCancel}
        footer={null}
        width={400}
        className="login-modal"
        destroyOnClose
      >
        <div>
          <div className="login-tab">{display}</div>
          <div className="mdnice-user-dialog-footer">
            <p>
              登录即同意{" "}
              <a
                href="https://product.mdnice.com/article/user/agreement/"
                target="_blank"
              >
                用户协议
              </a>{" "}
              和{" "}
              <a
                href="https://product.mdnice.com/article/user/private-policy/"
                target="_blank"
              >
                隐私政策
              </a>
            </p>
            {activeTab === "login" ? (
              <a onClick={() => setActiveTab("wechatLogin")}>
                微信登录/注册 &gt;
              </a>
            ) : (
              <a onClick={() => setActiveTab("login")}>邮箱登录 &gt;</a>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Navbar
