import React from "react"
import { LockOutlined, SendOutlined } from "@ant-design/icons"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Input, Button } from "antd"

import { signIn } from "../api/account"
import "./SignIn.css"

function SignIn(props) {
  const { getFieldDecorator } = props.form

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        signIn(values, () => {
          props.form.resetFields()
          props.successCallback()
        })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <h2>账号密码登录</h2>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "请输入邮箱" }],
          })(
            <Input
              prefix={<SendOutlined />}
              placeholder="邮箱地址，比如：example@exapmle.com"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "请输入密码" }],
          })(
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="请输入密码"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          <a onClick={()=> props.setActiveTab("reset")}>忘记密码</a>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          我要登录啦！
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(SignIn)
