import React, { useState } from "react"
import {
  LockOutlined,
  SendOutlined,
  SafetyOutlined,
} from "@ant-design/icons"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Input, Button, Row, Col } from "antd"

import { resetPass, sendResetEmail } from "../api/account"
import { RANDOM_CODE_COUNTDOWN } from "../utils/constant"
import "./Reset.css"

function Reset(props) {
  const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false)
  const [codeBtnText, setCodeBtnText] = useState("获取验证码")

  const { form, successCallback } = props
  const { getFieldDecorator } = form

  let countdownNum = RANDOM_CODE_COUNTDOWN
  function setCountdown() {
    if (countdownNum == 0) {
      setCodeBtnDisabled(false)
      setCodeBtnText("获取验证码")
      countdownNum = RANDOM_CODE_COUNTDOWN
    } else {
      setCodeBtnDisabled(true)
      setCodeBtnText("重新发送(" + countdownNum + ")")
      countdownNum--
      setTimeout(function() {
        setCountdown()
      }, 1000)
    }
  }

  const sendCodeEmail = e => {
    const email = form.getFieldValue("email")
    // 不等待请求返回先给提示，以免用户等待多次点击
    setCountdown()
    sendResetEmail({ email }, () => {})
  }

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        resetPass(values, () => {
          form.resetFields()
          successCallback()
        })
      }
    })
  }

  function validatePassword(rule, value, callback) {
    const compareVal = form.getFieldValue("passwordRepeat")
    if (!value) {
      callback("请输入密码")
    } else if (!/^[\w_]{6,16}$/.test(value)) {
      callback("密码必须为6-16位的数字、字母或下划线")
    } else {
      callback()
    }
  }

  async function validateRandomCode(rule, value) {
    if (!value) {
      return Promise.reject("请输入验证码")
    }
    return Promise.resolve()
  }

  async function validateEmail(rule, value) {
    if (
      !/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(
        value
      )
    ) {
      return Promise.reject("请输入规范的邮箱地址")
    }
    return Promise.resolve()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <h2>修改密码</h2>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, validator: validateEmail }],
          })(
            <Input
              prefix={<SendOutlined />}
              placeholder="邮箱地址，比如：example@exapmle.com"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          <Row gutter={16}>
            <Col span={15}>
              <Form.Item style={{ marginBottom: "0px" }}>
                {getFieldDecorator("randomCode", {
                  rules: [{ required: true, validator: validateRandomCode }],
                })(
                  <Input
                    prefix={<SafetyOutlined />}
                    placeholder="6 位邮箱验证码"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={9}>
              <Button disabled={isCodeBtnDisabled} onClick={sendCodeEmail}>
                {codeBtnText}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ validator: validatePassword }],
          })(<Input.Password prefix={<LockOutlined />} placeholder="密码" />)}
        </Form.Item>
        <Button className="reset-sumbit-btn" type="primary" htmlType="submit">
          我要更新啦！
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(Reset)
