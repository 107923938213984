import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getUserSelf = async () => {
  const token = getCookie(TOKEN);
  if (token === null) {
    message.warn("请登录之后进行操作")
    return null;
  }
  return await axiosBackEnd.get(
    `/users/self`,
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export const getUserVipBenefit = async () => {
  return await axiosBackEnd.get(`/users/vip`)
}

export const getUser = async (userOutId) => {
  return await axiosBackEnd.get(`/users/${userOutId}`)
}

export const getUserInvitationList = async (currentPage, pageSize) => {
  const token = getCookie(TOKEN);
  if (token === null) {
    message.warn("请登录之后进行操作")
    return null;
  }
  return await axiosBackEnd.get(
    `/users/invitations/self?currentPage=${currentPage}&pageSize=${pageSize}`,
    { headers: { Authorization: `Bearer ${token}` } }
  )
}