import { axiosBackEnd } from "../utils/helper"

export const getQrcode = async () => {
  return await axiosBackEnd.get("/wechat/qrcode");
}

export const checkLogin = async (sceneStr) => {
  return await axiosBackEnd.post("/wechat/checkLogin", {
    sceneStr
  }) ;
}