import React from "react"

import styles from "./footer.module.css"
import muji from "../icons/muji.png"
import leetcode from "../icons/leetcode.svg"
import chat from "../icons/chat.svg"
import soogif from "../icons/soogif.svg"
import logo from "../icons/logo.svg"
import h5dooring from "../icons/h5dooring.png"

export default () => {
  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.moreContainer}>
          <h4>更多产品</h4>

          <div className={styles.row}>
            <img alt="" src={soogif} className={styles.icon} style={{ marginLeft: "-3px" }} />
            <a
              rel="noopener noreferrer"
              href="https://www.soogif.com/tool-list"
              target="_blank"
            >
              SOOGIF动图工具
            </a>
          </div>
          <div className={styles.row}>
            <img alt="" src={h5dooring} className={styles.pngIcon} style={{ marginLeft: "-3px" }} />
            <a
              rel="noopener noreferrer"
              href="http://h5.dooring.cn"
              target="_blank"
            >
              H5页面制作工具
            </a>
          </div>
          <div className={styles.row}>
            <img alt="" src={muji} className={styles.mujiIcon} />
            <a
              rel="noopener noreferrer"
              href="https://www.mujicv.com"
              target="_blank"
            >
              木及简历
            </a>
          </div>
          <div className={styles.row}>
            <img alt="" src={leetcode} className={styles.icon} />
            <a
              rel="noopener noreferrer"
              href="https://draw.mdnice.com/algorithm/intro/"
              target="_blank"
            >
              力扣刷题
            </a>
          </div>
          <div className={styles.row}>
            <img alt="" src={chat} className={styles.icon} />
            <a
              rel="noopener noreferrer"
              href="https://qrbtf.com/"
              target="_blank"
            >
              艺术二维码生成
            </a>
          </div>
        </div>
        <div className={styles.openContainer}>
          <h4>开源</h4>
          <div className={styles.row}>
            <span className={styles.star}>Star 2.6k+ </span>
            <a
              rel="noopener noreferrer"
              href="https://github.com/mdnice/markdown-nice"
              target="_blank"
            >
              Markdown Nice 文章排版
            </a>
          </div>
          <div className={styles.row}>
            <span className={styles.star}>Star 600+ </span>
            <a
              rel="noopener noreferrer"
              href="https://github.com/mdnice/markdown-resume"
              target="_blank"
            >
              Markdown Resume 简历排版
            </a>
          </div>
          <div className={styles.row}>
            <span className={styles.star}>Star 100+ </span>
            <a
              rel="noopener noreferrer"
              href="https://draw.mdnice.com/"
              target="_blank"
            >
              编程如画博客
            </a>
          </div>
        </div>
        <div className={styles.noticeContainer}>
          <h4>帮助</h4>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="http://product.mdnice.com/article/user/solve/"
          >
            如何有效的解决 mdnice 相关问题？
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://product.mdnice.com/articles"
          >
            更多文档...
          </a>
        </div>
        <div className={styles.qrcodeContainer}>
          <img
            alt=""
            src="https://files.mdnice.com/pic/cd3ca20c-896f-4cfc-9bdd-c4c58e69ba26.jpg"
          />
        </div>
      </div>

      <div className={styles.hr}></div>

      <div className={styles.bottomContainer}>
        <img alt="" className={styles.logo} src={logo} />
        <p>本网站所有资料归 mdnice 和开发者版权所有</p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://beian.miit.gov.cn/"
        >
          京ICP备19016933号
        </a>
      </div>
    </div>
  )
}
